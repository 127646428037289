






















































































import { Component, Prop } from 'vue-property-decorator';
import {
  dispatchUpdatePanel,
  dispatchGetPanelById,
  dispatchGetBiomarkersForList,
  dispatchExpireDataByModuleName,
} from '@/store/crud/actions';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { ICreatePanel, IPanel } from '@/interfaces/panels';
import { IBiomarker } from '@/interfaces/biomarkers';
import { crudModules } from '@/constants/globalConstants';


@Component({})
export default class EditPanel extends AppComponent {
  @Prop({ type: String }) public panelId: string;

  public valid = false;
  /*
    package data
  */
  public name: string = '';
  public questTestCode: string = '';
  public modeOfAquisition: string = '';
  public questPrice: string = '';
  public description: string = '';

  private selectedBiomarkers: string[] = []; // biomarkers selected by user

  /*
    panel data
  */
  private panel: IPanel = {};

  public async mounted() {
    this.initScreen();
  }

  public async initScreen() {
    this.setAppLoading(true);
    if (this.panelId !== undefined && this.panelId.length > 0) {
      this.panel = await dispatchGetPanelById(this.$store, this.panelId);
      if (this.panel) {
        await dispatchGetBiomarkersForList(this.$store);
        this.reset();
      } else {
        this.$router.back();
      }
    } else {
      this.$router.back();
    }
    this.setAppLoading(false);
  }

  /*
    It resets the screen by charging the data / model values with the
    values from the panel gotten from Store.
    It also reset the form validations
  */
  public reset() {
    this.$validator.reset();
    if (this.panel) {
      this.name = this.panel.name || '';
      this.questTestCode = this.panel.questTestCode || '';
      this.modeOfAquisition = this.panel.modeOfAcquisition || '';
      this.questPrice = this.panel.questPrice ?? '';
      this.description = this.panel.description || '';
      this.selectedBiomarkers = this.panel.biomarkers && this.panel.biomarkers.length > 0
      ? this.getBiomarkerIdsFromBiomarkers(this.panel.biomarkers as IBiomarker []) : [] as string [];
    }
    this.$validator.reset();
  }

  /**
   * It cancels the operation and redirect to Admin Panels
   */
  public cancel() {
    this.$router.back();
  }

  /*
    It Submits the package update by checking if the model values have values
    If they dont, they are not included in the API call
  */
  public async submit() {
    if (await this.$validator.validateAll()) {
      const protoUpdatePanel: IPanel = {} as IPanel;

      this.setFieldIfChanges(protoUpdatePanel, 'name', this.name, this.panel.name);
      this.setFieldIfChanges(protoUpdatePanel, 'questTestCode', this.questTestCode, this.panel.questTestCode);
      this.setFieldIfChanges(protoUpdatePanel, 'modeOfAcquisition',
      this.modeOfAquisition, this.panel.modeOfAcquisition);
      this.setFieldIfChanges(protoUpdatePanel, 'description', this.description, this.panel.description);

      if (this.objectChanged(this.questPrice, this.panel.questPrice)) {
        if (!this.isValidFloat(this.questPrice)) {
          this.toast('Invalid value for Quest Price', true);
          return;
        }
        protoUpdatePanel.questPrice = this.questPrice;
      }

      const originalBiomarersIds = this.getBiomarkerIdsFromBiomarkers(this.panel.biomarkers as IBiomarker []);
      if (this.isListDifferent(this.selectedBiomarkers, originalBiomarersIds)) {
        protoUpdatePanel.biomarkers = this.selectedBiomarkers as string [];
      }

      const updatePanel: ICreatePanel = protoUpdatePanel as ICreatePanel;
      if (Object.keys(updatePanel).length > 0) {
        const result = await dispatchUpdatePanel(this.$store, {panelId: this.panelId, panel: updatePanel});
        if (result) {
          this.$router.push('/main/content/panels');
          // expire the packages data to force a fetch from the server
          dispatchExpireDataByModuleName(this.$store, crudModules.PACKAGES);
        }
      }
    }
  }


}
